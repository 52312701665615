























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { TheMask } from 'vue-the-mask'

@Component({
  components: {
    TheMask
  }
})
export default class EditUserPopup extends Vue {
  @Prop() popupId!: string
  @Prop() userId!: number
  public user = {
    name: this.$store.state.userData.name,
    email: this.$store.state.userData.email,
    role: this.$store.state.userData.role,
    mobile_number: ''
  }
  public busy: boolean = false
  public mobile = ''
  public code = ''

  getMobile () {
    let phone = this.$store.state.userData.mobile_number
    if (phone === null || phone === undefined) {
      this.code = '+1'
      this.mobile = ''
      return
    }
    let start = phone.length - 10
    if (start < 0) start = 0
    this.code = phone.substring(0, start)
    if (this.code.length < 2) this.code = '+1'
    else if (!this.code.startsWith('+')) this.code = '+' + this.code
    this.mobile = phone.substring(start, start + 10)
  }

  created () {
    this.getMobile()
  }

  updateProfile () {
    this.busy = true
    this.$validator.validateAll('edit-profile').then((result) => {
      if (result) {
        let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
        this.user.mobile_number = this.code + this.mobile
        this.$store.dispatch('updateProfile', { id: this.userId, user: this.user }).then(() => {
          popupCloseButton.click()
          this.$store.dispatch('getUserData').then(() => {
            this.getMobile()
            this.user = {
              name: this.$store.state.userData.name,
              email: this.$store.state.userData.email,
              mobile_number: '',
              role: this.$store.state.userData.role
            }
          })
          this.$notify({
            text: 'Profile Changes Saved Successfully!',
            type: 'success'
          })
          this.$validator.errors.clear()
        }, (response: any) => {
          for (var key in response.body) {
            this.$notify({ type: 'error', text: response.body[key][0] })
            let field: any = this.$validator.fields.find({ name: key, scope: 'edit-profile' })

            if (field) {
              this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: response.body[key][0],
                scope: 'edit-profile'
              })

              field.setFlags({
                invalid: true,
                valid: false,
                validated: true
              })
            }
          }
        })
      }
      this.busy = false
    }, () => { this.busy = false })
  }
}
